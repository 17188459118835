<template>
  <div class="box">
    <div id="xgPlayer"></div>
		<overlay :config="{...config,playStatus}"/>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "vue";
import Player from "xgplayer";
import "xgplayer/dist/index.min.css";
import XgplayerHls from "xgplayer-hls.js";
import overlay from "./components/overlay.vue"
export default {
	components:{
		overlay
	},
	props:{
		config:{}
	},
  setup(props) {
    const state = reactive({
      xgPlayer: null,
      playStatus: "start", // 播放状态
      // playbackRate:false
    });

    function initXgPlayer() {
			if(!props.config.url){
        state.playStatus = 'faild'
        return
      }
      state.xgPlayer = new Player({
        id: "xgPlayer",
        url: props.config.url,
        height: "100%",
        width: "100%",
        autoplay: true,
        plugins: [XgplayerHls],
        isActive: true,
      });
      // 监听播放错误
      state.xgPlayer.on("HLS_ERROR", (error) => {
        if (error.errorType === 'networkError'){
					state.playStatus = 'faild'
					state.xgPlayer.destroy()
				}
      });
      // 监听播放器结束
      state.xgPlayer.on('ended', () => {
        state.playStatus = 'ended'
      })
      // state.xgPlayer.play()
    }

    watch(
      () => props.config,
      () => {
				initXgPlayer()
			},
      {deep:true}
    );
    onMounted(() => {
      // initXgPlayer();
    });
		return {...toRefs(state)}
  },
};
</script>

<style lang="scss" scoped>
:deep(.xgplayer-playbackrate){
	display: none !important;
}
#xgPlayer {
  width: 100%;
  height: 100%;
  // background: pink;
}
.box{
	height: 100%;
	position: relative;
}
</style>
