<template>
  <div class="box" @click="downLoadFn" style="height: 100%">
    <Component :is="view" :config="{ masterStationUrl }"></Component>
    <!-- 滑块验证码 -->
    <ElDialog
      v-model="showDrag"
      width="300px"
      :show-close="false"
      top="30vh"
      class="p-0 slider-sty download-slider-dialog"
      :lock-scroll="false"
      :close-on-press-escape="false"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      append-to-body
      modal-class="drag-dialog"
    >
      <DragVerify
        ref="dragRefs"
        :showDrag="showDrag"
        :l="42"
        :r="10"
        :w="verifyData.w"
        :h="verifyData.h"
        :blocky="verifyData.blocky"
        :slider-text="verifyData.text"
        @verifyResult="verifyResult"
        @fail="onFail"
        @onCloseDia="onCloseDia"
      />
    </ElDialog>
    <!-- 数字验证 -->
  </div>
  <CodeVerify v-model:show="isShowCodeVerify" @verifyResult="verifyResult2" />
</template>
<script>
import {
  onMounted,
  reactive,
  toRefs,
  onBeforeUnmount,
  provide,
  watch,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import edition1 from "./components/edition1.vue";
import edition2 from "./components/edition2.vue";
import edition1a from "./components/edition1a.vue";
import edition2a from "./components/edition2a.vue";
import edition3 from "./components/edition3.vue";
import edition4 from "./components/edition4.vue";
import service from "@/axios/index.js";
import { useStore } from "vuex";
import DragVerify from "@/components/DragVerify/index.vue";
import mixins from "@/components/DragVerify/drag-verify.js";
import { ElDialog } from "element-plus";
import CodeVerify from "@/components/CodeVerify/index.vue";
import { Encrypt } from "@/utils/aes.js";
import axios from "axios";
import qs from "qs";

export default {
  components: {
    edition1,
    edition2,
    edition1a,
    edition2a,
    edition3,
    edition4,
    ElDialog,
    DragVerify,
    CodeVerify,
  },
  setup() {
    let {
      verifyData,
      onFail,
      onSuccess,
      onCloseDia,
      verifyResult,
      isSliderSuccessFlag,
      showDrag,
      dragRefs,
    } = mixins();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      view: route.name,
      iPhone_download_url: "", // iPhone下载链接
      android_download_url: "", // Android下载链接
      masterStationUrl: "", //主站地址
      agtShare_site: "", //pc推广地址
      webImgurl: "", //图片地址
    });
    provide("downLoadFn", downLoadFn);
    let isShowCodeVerify = ref(false);

    const downloadApp = () => {
      service({
        method: "POST",
        url: "/base/getConfigPub",
        data: {
          key: ["apkDownUrl"],
        },
      }).then((res) => {
        let id = Number(route.query.id);
        if (id) {
          service({
            method: "POST",
            url: "/user/bindIp",
            data: {
              agentId: Number(id),
            },
          }).then(() => {
            downLoadRecordsFn(2, id);
            window.location.href = res?.data?.data?.apkDownUrl;
          });
        } else {
          downLoadRecordsFn(2);
          window.location.href = res?.data?.data?.apkDownUrl;
        }
      });
    };
    function downLoadRecordsFn(type, id = "") {
      let obj = {
        deviceType: type,
      };
      if (id) {
        obj.agtId = id;
      }
      const d = new Date();
      axios({
        method: "POST",
        url: "/analyse/download",
        data: {
          endata: Encrypt(JSON.stringify(obj)),
          ents: Encrypt(
            parseInt(d.getTime() / 1000) + d.getTimezoneOffset() * 60
          ),
        },
      });
    }
    function downLoadFn() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        // window.open('itms-services:///?action=download-manifest&url='+this.url, '_self')
        bindIP("iPhone_download_url");
        return;
      } else {
        bindIP("android_download_url");
      }
    }
    // 绑定ip
    function bindIP(type) {
      if (type == "android_download_url") {
        service({
          method: "POST",
          url: "/captcha/getConfig",
          data: {
            sceneType: 4,
          },
        }).then((res) => {
          const verifyType = res?.data?.data?.captchaTypes?.[0];
          if (verifyType == 4) {
            showDrag.value = true;
          } else if (verifyType == 5) {
            isShowCodeVerify.value = true;
          } else {
            downloadApp();
          }
        });
        return;
      }
      let id = Number(route.query.id);
      if (id) {
        service({
          method: "POST",
          url: "/user/bindIp",
          data: {
            agentId: Number(id),
          },
        }).then((res) => {
          downLoadRecordsFn(6, id);
          window.location.href = data[type];
        });
      } else {
        downLoadRecordsFn(6);
        window.location.href = data[type];
      }
    }
    //   获取public接口
    function getPubConfig() {
      service({
        method: "POST",
        url: "/base/getConfigPub",
        data: {
          key: [
            // "apkDownUrl",
            "ipaDownUrl",
            "ipaBookmarkUrl",
            "agtShare_site",
            "masterStationUrl",
            "webImgurl",
            "agtShare_site",
          ],
        },
      }).then((res) => {
        const {
          apkDownUrl,
          ipaBookmarkUrl,
          agtShare_site,
          masterStationUrl,
          webImgurl,
        } = res.data.data;
        data.iPhone_download_url = ipaBookmarkUrl;
        data.android_download_url = apkDownUrl;
        data.agtShare_site = agtShare_site;
        data.masterStationUrl = masterStationUrl;
        data.webImgurl = webImgurl;
        store.commit("set_webImgurl", webImgurl);
        goPc();
      });
    }
    function IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    }
    function goPc() {
      if (IsPC()) {
        // 获取当前 URL
        var url = data.agtShare_site;
        // 创建一个虚拟的 anchor 元素
        var dummyAnchor = document.createElement("a");
        // 将 anchor 元素的 href 属性设置为 URL
        dummyAnchor.href = url;
        // 获取 URL 的路径名（路由）
        var route = dummyAnchor.pathname;
        router.push({ path: url ? route : "/download1", query: route.query });
      }
    }

    // 前往h5官网
    function goMasterUrl(obj) {
      let masterStationUrl = data.masterStationUrl.endsWith("/")
        ? data.masterStationUrl
        : data.masterStationUrl + "/";
      let url = obj?.videoId
        ? masterStationUrl + `play/video/${obj.videoId}/0`
        : masterStationUrl;
      let params = route.query;
      // 删除为空的字段
      for (let key in params) {
        if (params[key] === "") {
          delete params[key];
        }
      }
      const queryString = qs.stringify(params);
      window.location.href =`${url}?${queryString}`
    }
    provide("goMasterUrl", goMasterUrl);

    onMounted(() => {
      window.addEventListener("resize", goPc);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", goPc);
    });
    getPubConfig();
    watch(
      () => isSliderSuccessFlag.value,
      (v) => {
        if (v) {
          isSliderSuccessFlag.value = false;
          downloadApp();
        }
      },
      {
        deep: true,
      }
    );
    function verifyResult2() {
      isShowCodeVerify.value = false;
      downloadApp();
    }
    onMounted(() => {
      try {
        document
          .querySelector(".drag-dialog")
          .addEventListener("touchstart", function (e) {
            if (e?.touches?.length > 1) {
              e?.preventDefault?.();
            }
          });
        document
          .querySelector(".drag-dialog")
          .addEventListener("touchmove", function (e) {
            e?.preventDefault?.();
          });
      } catch (error) {}
    });
    return {
      ...toRefs(data),
      downLoadFn,
      verifyData,
      onFail,
      onSuccess,
      onCloseDia,
      verifyResult,
      isSliderSuccessFlag,
      showDrag,
      dragRefs,
      verifyResult2,
      isShowCodeVerify,
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 0;
  margin: 0;
}
</style>
