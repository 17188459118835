// 播放量
export function seecount(data) {
  if (data >= 10000) {
    let numStr = data.toString().split(".")[0];
    if (numStr.length < 4) {
      return numStr;
    } else if (numStr.length >= 4 && numStr.length <= 8) {
      let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + 1);
      return parseFloat(parseInt(data / 10000) + "." + decimal) + "万";
    } else if (numStr.length > 8) {
      let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + 1);
      return parseFloat(parseInt(data / 100000000) + "." + decimal) + "亿";
    }
    // return tranWan(data, 1)
  } else {
    return data;
  }
}

//计算视频时长
export function duration(data) {
  if (!data) {
    return 0;
  }
  let s = data % 60;
  if (s < 10) s = "0" + s;
  let m = (data - s) / 60;
  let h = 0;
  let time = m + ":" + s;
  if (m >= 60) {
    h = (m - (m % 60)) / 60;
    m = m % 60;
    if (m < 10) m = "0" + m;
    time = h + ":" + m + ":" + s;
  }
  return time;
}

//根据参数名获取对应的url参数
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
