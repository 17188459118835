<template>
  <div
    class="coverBox3"
    id="dynamicBg"
    :style="{ backgroundImage: 'url(' + backImg + ')' }"
  >
    <div class="titlePng">
      <aesImg color="#ffffff" :src="titleImg" />
    </div>
    <div class="dynamicBox">
      <aesImg
        color="#ffffff"
        class="coverImg"
        width="auto"
        height="auto"
        :src="gifImg"
      />
    </div>
    <div class="btn3">
      <div
        class="btn btn-and goMasterUrl"
        style="width: 100%"
        @click.stop="goMasterUrl"
      >
        前往官网
      </div>
      <div style="display: flex; justify-content: space-between">
        <div>
          <div class="btn btn-and" id="eAndroid">
            <svg
              t="1622038313232"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1204"
              width="26"
              height="26"
            >
              <path
                d="M287.01171846 737.01171846c0 20.62392627 16.87412109 37.49804737 37.49804736 37.49804736l37.49804736 0 0 131.24316446c0 31.12337901 25.12369161 56.2470706 56.2470706 56.2470706s56.2470706-25.12369161 56.24707062-56.2470706l0-131.24316445 74.99609385-1e-8 0 131.24316446c0 31.12337901 25.12369161 56.2470706 56.2470706 56.2470706s56.2470706-25.12369161 56.2470706-56.2470706l0-131.24316445 37.49804737-1e-8c20.62392627 0 37.49804737-16.87412109 37.49804736-37.49804736l0-374.98047012-449.97656397 0 0 374.98047012zM193.26660049 362.03124834c-31.12337901 0-56.2470706 25.12369161-56.24707061 56.24707061l0 262.48632891c0 31.12337901 25.12369161 56.2470706 56.24707061 56.2470706s56.2470706-25.12369161 56.2470706-56.2470706l0-262.48632891c0-31.12337901-25.12369161-56.2470706-56.2470706-56.24707061zM830.73339951 362.03124834c-31.12337901 0-56.2470706 25.12369161-56.2470706 56.24707061l0 262.48632891c0 31.12337901 25.12369161 56.2470706 56.2470706 56.2470706s56.2470706-25.12369161 56.24707061-56.2470706l0-262.48632891c0-31.12337901-25.12369161-56.2470706-56.24707061-56.24707061zM644.36810557 143.0426542l48.93495117-48.93495118c7.31211943-7.31211943 7.31211943-19.1240042 0-26.43612275s-19.1240042-7.31211943-26.43612276 0l-55.49710956 55.30961953c-29.99843789-14.81172891-63.55919004-23.4362795-99.36982442-23.43627949-35.99812529 0-69.74636748 8.62455058-99.93229541 23.62376953l-55.68459961-55.68459961c-7.31211943-7.31211943-19.1240042-7.31211943-26.43612275 0s-7.31211943 19.1240042 0 26.43612276l49.12244121 49.12244121c-55.68459961 41.06036162-92.05770498 107.05692393-92.05770498 181.49054765l449.97656396 0c0-74.62111377-36.56059541-140.61767607-92.62017597-181.49054765zM437.00390615 249.53710713l-37.49804737 0 0-37.49804736 37.49804737 0 0 37.49804736zM624.49414122 249.53710713l-37.49804737 0 0-37.49804736 37.49804737 0 0 37.49804736z"
                fill="#ffffff"
                p-id="1205"
              ></path>
            </svg>
            <div class="btnText">Android版下载</div>
          </div>
          <div class="btn-bottom">
            <div href="./andTutorials.html" @click.stop="goTutorials('and')">
              Android版下载安装说明
            </div>
          </div>
        </div>
        <div>
          <div class="btn btn-ios" id="eIphone">
            <svg
              t="1622038757580"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1506"
              width="26"
              height="26"
            >
              <path
                d="M817.3795552 853.33333333C779.60533333 909.76711147 739.5555552 964.8355552 678.57066667 965.74577813 617.58577813 967.11111147 598.016 929.792 528.83911147 929.792 459.20711147 929.792 437.81688853 964.8355552 380.01777813 967.11111147 320.39822187 969.38666667 275.34222187 907.0364448 237.11288853 851.968 159.28888853 739.5555552 99.66933333 532.48 179.76888853 393.216 219.3635552 324.03911147 290.36088853 280.3484448 367.27466667 278.98311147 425.52888853 278.07288853 481.0524448 318.57777813 517.00622187 318.57777813 552.50488853 318.57777813 619.86133333 269.88088853 690.4035552 277.16266667 719.98577813 278.528 802.816 288.9955552 856.064 367.27466667 851.968 370.00533333 757.30488853 425.52888853 758.21511147 540.672 759.5804448 678.1155552 878.8195552 724.08177813 880.18488853 724.53688853 878.8195552 727.72266667 861.07022187 790.07288853 817.3795552 853.33333333M557.51111147 125.1555552C590.73422187 87.38133333 645.80266667 58.70933333 691.31377813 56.88888853 697.23022187 110.13688853 675.84 163.84 643.98222187 202.06933333 612.5795552 240.75377813 560.69688853 270.79111147 509.7244448 266.69511147 502.89777813 214.35733333 528.384 159.744 557.51111147 125.1555552Z"
                fill="#ffffff"
                p-id="1507"
              ></path>
            </svg>
            <div class="btnText">ios轻量版下载</div>
          </div>
          <div class="btn-bottom">
            <div href="./iosTutorials.html" @click.stop="goTutorials('ios')">
              iPhone版下载安装说明
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch,inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { seecount, duration, getQueryString } from "@/utils/index.js";
import aesImg from "@/components/aesImg.vue";
import videoCard from "@/components/videoCard.vue";
import { Decrypt } from "@/utils/decryptAesImage.js";
import axios from "axios";
import { useStore } from "vuex";
export default {
  components: { aesImg, videoCard },
  props: {
    config: {},
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      page: 1,
      list: [],
      backImg: "",
      gifImg: "",
      titleImg: "",
    });
    const goMasterUrl = inject("goMasterUrl");
    // data.gifImg = computed(() => {
    //   if (!store.state.webImgurl) return;
    //   return store.state.webImgurl + process.env.VUE_APP_API_GIFIMG_URL;
    // });
    // data.titleImg = computed(() => {
    //   if (!store.state.webImgurl) return;
    //   return store.state.webImgurl + process.env.VUE_APP_API_TITLEIMG_URL;
    // });
    function goTutorials(type) {
      if (type == "and") {
        router.push({ path: "/andTutorials" });
      } else if (type == "ios") {
        router.push({ path: "/iosTutorials" });
      }
    }
    watch(
      () => store.state.webImgurl,
      (newV) => {
        if (!newV) return;
        if (process.env.VUE_APP_API_BACKIMG_URL) {
          if (process.env.VUE_APP_API_BACKIMG_URL?.search(/.aes$/) > -1) {
            axios({
              url: newV + process.env.VUE_APP_API_BACKIMG_URL,
              method: "get",
            })
              .then((res) => {
                data.backImg = Decrypt(res.data);
              })
              .catch(() => {
                data.backImg = newV + process.env.VUE_APP_API_BACKIMG_URL;
              });
          } else {
            data.backImg = newV + process.env.VUE_APP_API_BACKIMG_URL;
          }
        }else{
          data.backImg=require("@/assets/imgs/back3.png")
        }

        data.gifImg = process.env.VUE_APP_API_GIFIMG_URL
          ? newV + process.env.VUE_APP_API_GIFIMG_URL
          : require("@/assets/dynamic/assets.gif");
        data.titleImg = process.env.VUE_APP_API_TITLEIMG_URL
          ? newV + process.env.VUE_APP_API_TITLEIMG_URL
          : require("@/assets/imgs/title.png");
      },
      { immediate: true }
    );
    onMounted(() => {});

    return {
      ...toRefs(data),
      seecount,
      duration,
      goMasterUrl,
      goTutorials,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn3 {
  position: fixed;
  bottom: 30px;
  /* left: 50%;
    transform: translateX(-50%); */
  width: calc(100% - 68px);
  padding: 0 34px;
  z-index: 999;
}
.theme-3 {
  background-image: url("@/assets/imgs/back3.png");
  background-size: cover;
  background-position: center center;
}
.dynamicBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
}
.titlePng {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 888;
}
.itemBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
  .item {
    margin-bottom: 8px;
    .bigImgBox {
      width: calc(359px - 15px - 15px);
      height: 223px;
      display: block;
      background-image: url("@/assets/imgs/border.png");
      background-size: 100% 100%;
      /* background-position: center center; */
      background-repeat: no-repeat;
      padding: 8px;
      position: relative;
      overflow: hidden;
    }
    .g-small-content {
      width: 168px;
      .titleText {
        color: #333;
        font-family: PingFang SC;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        margin-top: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 40px;
      }
    }
  }
}
.coverBox3 {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* 背景平铺方式：水平和垂直均平铺 */
  background-repeat: no-repeat;
  /* 背景尺寸：自适应 */
  background-size: cover;
  background-position: center;
  // padding-top: 44px;
  // display: none;
  .btn {
    position: relative;
    margin: 0 auto;
    margin-bottom: 8px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-radius: 6px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    border-radius: 20px;
  }
  .btn-and {
    border: 1px solid #ff6565;
  }
  .btn-ios {
    border: 1px solid #96fff9;
  }
  .btn-bottom {
    text-align: center;
    margin-bottom: 20px;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #333;
  }
}
</style>
