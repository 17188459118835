<template>
  <div class="cover" v-if="config.playStatus=='faild'">
    <aesImg :src="config.coverImgUrl || config.coverImgUrlVertical" />
    <div class="Tips">当前视频仅限官网/APP内观看哦</div>
  </div>
</template>

<script>
import aesImg from "@/components/aesImg.vue";
export default {
  props: {
    config: {},
  },
  components: {
    aesImg,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .Tips {
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
		background: rgba(0, 0, 0, 0.7);
		text-align: center;
		padding: 6px;
  }
}
</style>
