<template>
  <div class="color-bg-card">
    <!-- 内容(上) -->
    <div class="small-top">
      <!-- 默认图片 -->
      <aesImg
        :color='color'
        class="coverImg"
        :src="config.coverImgUrl || config.coverImgUrlVertical"
      />
      <div class="content" style="padding-bottom: 7px">
        <div class="content-left">
          <img src="@/assets/imgs/Frame.png" />
          <div class="content-left-num g-flex-jcc g-flex-aic">
            {{ seecount(config.seeCount) }}
          </div>
        </div>
        <!-- 时间 -->
        <div class="content-time">{{ duration(config.length) }}</div>
      </div>
      <!-- 角标 -->
      <!-- <div class="cornerMark-chunk ${tag.type}">
        ${ tag.name ? tag.name : "" }
      </div> -->
    </div>

    <!-- 内容(下) -->
    <div class="g-m-r-8">
      <div class="titleText">{{ config.name }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { seecount, duration } from "@/utils/index.js";
import aesImg from "@/components/aesImg.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { aesImg },
  props: {
    config: {},
  },
  setup() {
    const route = useRoute();
    const color = route.path=='/edition2'?'#BEDDFF':'#FFBEF0'
    //角标
    function tagFn(data) {
      if (data.buy) {
        return {
          type: "isBuy",
          name: "已购",
        };
      }
      // 查看标签值更改 tagList 里面的状态 (tagList只能存在一个true)
      switch (data.isAngle) {
        case 0:
          return {
            type: false,
          };
        case 1:
          return {
            type: "isExclusive",
            name: "独家",
          };
        case 2:
          return {
            type: "isMake",
            name: "出品",
          };
        case 3:
          return {
            type: "isExempt",
            name: "限免",
          };
        case 4:
          return {
            type: "isAdvertising",
            name: "广告",
          };
        case 11:
          return {
            type: "isVip",
            name: "VIP",
          };
        case 12:
          return {
            type: "isPay",
            name: "付费",
          };
        case 13:
          // emit('handlCombine')
          if (props.show) {
            return {
              type: "isCompilations",
              name: "合集",
            };
          } else {
            return {
              type: false,
            };
          }
        default:
          return {
            type: false,
          };
      }
    }
    return {
      seecount,
      duration,
      tagFn,
      color
    };
  },
});
</script>

<style lang="scss" scoped>
.small-top {
  width: 168px;
  height: 101px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.titleText {
  position: relative;
  color: #333;
  font-family: PingFang SC;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  margin-top: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 40px;
}
.small-top.mark {
  top: 0;
  right: 0;
}
.small-top img {
  width: 100%;
  height: 100%;
}
.content {
  height: 20px;
  background: linear-gradient(
    180deg,
    rgba(46, 46, 46, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .content-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .content-left img {
    width: 16px;
    height: 12px;
    margin: 0 4px 0 6px;
  }
  .content-time {
    height: 16px;
    right: 10px;
    bottom: 3px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    padding-right: 8px;
  }
}
</style>
